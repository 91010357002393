 
import React, { Component } from 'react';
export default class Address extends Component {
    constructor(props) {
        super(props);
         this._ismounted = false;
        
        this.state = {
            walletAccount: props.walletAccount || ''
            
        }
       
    }
   componentDidMount = () => { 
        this._ismounted = true;
         document.addEventListener('walletConnected',  async({ detail }) => { 
             //console.log(detail, "wallet connected details")
          if( this._ismounted){
            
            this.setState({walletAccount:detail }); 
     
          }
            
        })
    }
    
render() {
    return (
    <>
      {this.state.walletAccount && <span id="walletAccountAddress">
      {((this.state.walletAccount).toString()).slice(0, 6)}...{(this.state.walletAccount?.toString()).slice((this.state.walletAccount?.toString()).length - 4, (this.state.walletAccount?.toString()).length)}
    </span>}
      
    </>
  );
};
componentWillUnmount() {
    this._ismounted = false;
   
    window.removeEventListener('walletConnected',  async( ) => { }, false);
  }
}
 