export const socialLinksWhitelist = [
  {
    name: 'Whatsapp',
    link: `https://api.whatsapp.com/send?text=I+purchased+the+K-Club+NFT+.+I+think+you’ll+like+this+too.+Check+it+out %0A%0A ${
      window.location.protocol}//${window.location.hostname}${window.location.port ? ':' + window.location.port : ''}/presale/bsc`,
    label: 'Whatsapp',
    icon: 'fa fa-whatsapp',
    className: 'btn-success',
  },
  {
    name: 'Twitter',
    link: `https://twitter.com/intent/tweet?text=I+purchased+the+K-Club+NFT+.+I+think+you’ll+like+this+too.+Check+it+out%3F&url=${
      window.location.protocol
    }%2F%2F${window.location.hostname}${window.location.port ? ':' + window.location.port : ''}%2Fpresale%2Fbsc`,
    label: 'Twitter',
    icon: 'fa fa-twitter',
    className: 'btn-info',
  },
  // {
  //   name: 'Facebook',
  //   link: `https://www.facebook.com/sharer.php?u=https%3A%2F%2Fkclub.ekta.io%2Fpresale%2Fwhitelist`,
  //   label: 'Share on Facebook',
  //   icon: 'fa fa-facebook',
  //   className: 'btn-primary',
  // },
  {
    name: 'Email',
    link: `mailto:?subject=I purchased the K-Club NFT. I think you’ll like this too. Check it out &body=${
      window.location.protocol
    }//${window.location.hostname}${window.location.port ? ':' + window.location.port : ''}/presale/bsc`,
    label: 'Email',
    icon: 'fa fa-envelope-o',
    className: 'btn-danger',
  },
];

export const socialLinksPurchase = [
  {
    name: 'Whatsapp: Share your personal referral link with your friends & get people to purchase Exclusive KClub Membership NFT to receive your Silent Investor membership!',
    link: `https://api.whatsapp.com/send?text=I+am+a+proud+owner+of+a+K-Club+NFT.+Don't+miss+out.+Purchase+yours+today.%0A%0AStep+1:+Install+Metamask.+%0D%0AStep+2:+Fund+Metamask+account+with+ETH+and+USDT+or+Ekta+NFT+Price+$1000+USD+Plus+fees+(min+fees+5%+of+transaction).+%0D%0AStep+3:+Visit+KClub+DApp+with+following+link+bellow.+%0D%0AStep+4:+Buy+K-Club+Exclusive+Membership+NFT.+%0D%0AStep+5:+Refer+like-minded+investors+who+would+like+to+join+the+Kabana+Tribe+to+earn+rewards+and+go+into+the+running+to+level+up+your+NFT.+%0D%0AVisit+DApp+Link:+${
      window.location.protocol}%2F%2F${window.location.hostname}${window.location.port ? ':' + window.location.port : ''}%2F`,
    label: 'Whatsapp',
    icon: 'fa fa-whatsapp',
    className: 'btn-success',
  },
  {
    name: 'Twitter: Share your personal referral link with your friends & get people to purchase Exclusive KClub Membership NFT to receive your Silent Investor membership!',
    link: `https://twitter.com/intent/tweet?text=I+am+a+proud+owner+of+a+Exclusive+KClub+Membership+NFT+.+Don't+miss+out.+%0D%0AVisit+DApp+Link+&url=${
      window.location.protocol
    }%2F%2F${window.location.hostname}${window.location.port ? ':' + window.location.port : ''}%2F`,
    label: 'Twitter',
    icon: 'fa fa-twitter',
    className: 'btn-info',
  },
  {
    name: 'Share your personal referral link with your friends & get people to purchase Exclusive KClub Membership NFT to receive your Silent Investor membership!',
    link: `mailto:?subject=I am a proud owner of a K-Club NFT. Don't miss your chance. Puchase yours today. &body=%0D%0AStep 1: Install Metamask https://metamask.io/download/. %0D%0AStep 2: Fund Metamask account with ETH and USDT or Ekta NFT Price $1000 USD + Fees (min fees 5% of transaction). %0D%0AStep 3: Visit KClub DApp with following link bellow. %0D%0AStep 4: Buy K-Club Exclusive Membership NFT. %0D%0AStep 5: Refer like-minded investors who would like to join the Kabana Tribe to earn rewards and go into the running to level up your NFT. %0D%0AVisit+DApp+Link: ${ window.location.protocol}//${window.location.hostname}${window.location.port ? ':' + window.location.port : ''}` ,
    label: 'Email',
    icon: 'fa fa-envelope-o',
    className: 'btn-ekta',
  },
];
