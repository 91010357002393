import React from 'react'

import './Section.css'

export type GapProps = {
  /**
   * Is the gap enabled?
   */
  enabled: boolean
}

const Gap: React.FC<GapProps> = ({ enabled }) => (
  <>
    {
      /**
       * Add a gap element if enabled.
       */
      enabled ? <span className="section-gap block" /> : null
    }
  </>
)

export default Gap
