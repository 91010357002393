//import { faAlignCenter } from "@fortawesome/free-solid-svg-icons"

export const containerStyle = {
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center', 
    flexDirection: 'column', 
    height: '100%'
  }
  
export const buttonStyle = {
    backgroundColor: '#181A20',
    fontSize: '14px', 
    color: '#FFF',
    borderRadius: '4px',
    border: '1px solid #181A20',
    display: 'flex',
    padding: '5px 7.5px',
    cursor: 'pointer',
    justifyContent: 'center',
    maxWidth: '130px'
}
  
export const leftStatus = {
    width: '12px', 
    height: '100%', 
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'center', 
    flexShrink: 0,
    marginRight: '5px'
}

export const statusIconConnected = {
    height: '5px', width: '5px', 
    borderRadius: '50%', 
    marginTop: '1.5px', 
    backgroundColor: 'green'
}

export const statusIconDisconnected = {
    height: '5px', 
    width: '5px', 
    borderRadius: '50%', 
    marginTop: '1.5px', 
    backgroundColor: 'red'
}

export const rowStyle = {
    margin: '10px 0px',
    width: '500px',
    display: 'flex', 
    justifyContent: 'center', 
    borderRadius: '4px', 
    border: '1px solid #fd584f', 
    padding: '10px'
}

export const inputStyle = {
    width: '50%', 
    backgroundColor: 'inherit',  
    border: '1px solid #ccc',  
    color: 'inherit',
    overflow: 'hidden',
    minHeight:'30px',
    textOverflow: 'ellipsis',
    margin: '10px 0px'
}

export const modalStyle = {
    position: 'fixed', 
    width: '70%',
    maxWidth: '600px',
    height: 'calc(100% - 100px)', 
    border: '1px solid #fff', 
    borderRadius: '4px', 
    top: '0%', 
    left: '50%',
    padding:'30px 0px',
    transform: 'translate(-50%, 50px)', 
    backgroundColor: '#000', 
    color:'#fff',
    overflow:'auto',
    maxHeight:'calc(100% - 100px)',
    display: 'block', 
    zIndex:'99999999',
    justifyContent: 'center', 
    alignItems: 'center', 
    flexDirection: 'column',
    boxShadow: '0px 0px 10px 0px rgb(0,0,0,0.3)'
 
}
export const modalStyleAlreadyRewardedDynamic = {
    position: 'fixed', 
    width: '70%',
    overflow:'auto',
    height:'calc(100% - 100px)',
    maxWidth: '600px',
    
    border: '1px solid #fff', 
    borderRadius: '4px', 
    top: '0%', 
    left: '50%',
    padding:'0px', 
    transform: 'translate(-50%, 50px)', 
    backgroundColor: '#000', 
    color:'#fff',
    display: 'block', 
    zIndex:'99999999',
    justifyContent: 'center', 
    alignItems: 'center', 
    flexDirection: 'column',
    boxShadow: '0px 0px 10px 0px rgb(0,0,0,0.3)'
 
}
export const modalStyleDynamic = {
    position: 'fixed', 
    width: '70%',
    overflow:'auto',
    maxHeight:'calc(100% - 100px)',
    maxWidth: '600px',
    height: 'auto', 
    border: '1px solid #fff', 
    borderRadius: '4px', 
    top: '0%', 
    left: '50%',
    padding:'0px', 
    transform: 'translate(-45%, 50px)', 
    backgroundColor: 'inherit', 
    color:'inherit',
    display: 'block', 
    zIndex:'99999999',
    justifyContent: 'center', 
    alignItems: 'center', 
    flexDirection: 'column',
    boxShadow: '0px 0px 10px 0px rgb(0,0,0,0.3)'
 
}

export const balanceModalStyle = {
    position: 'fixed', 
    width: '70%',
    maxWidth: '600px',
    height: '350px', 
    border: '1px solid #fd584f', 
    borderRadius: '4px', 
    top: '50%', 
    left: '50%', 
    transform: 'translate(-50%, -50%)', 
    backgroundColor: 'black'
}

export const modalHeaderStyle = {
    marginTop: '100px', 
    display: 'flex',  
    alignItems: 'center', 
    justifyContent: 'center', 
    width: '100%'
}