import React, {useEffect,useState} from 'react'
import classNames from 'classnames'

import SEO from '../../components/kabana/SEO'
import Section from '../../components/kabana/Section'
import Footer from '../../components/kabana/Footer'
import Button from '../../components/kabana/Button'
import Card from '../../components/kabana/Card'
import Hero from '../../components/kabana/Hero'
import List from '../../components/kabana/List'
import Testimonials from '../../components/kabana/Testimonials'
import Divider from '../../components/kabana/Divider'
import HeaderSection from './sections/Header'
import Container from '../../components/kabana/Container'
import ImageSlider from '../../components/kabana/Slider/ImageSlider'
import { socialLinksPurchase } from '../../data/socialLinksWhitelist'
import data from '../../data/data.json'
import firstGapImageBackground from '../../assets/images/covers/cover1.png'
import secondGapImageBackground from '../../assets/images/covers/cover2.png'
import social1 from '../../assets/social/1.png'
import social2 from '../../assets/social/2.png'
import social3 from '../../assets/social/3.png'
import social4 from '../../assets/social/4.png'
import social5 from '../../assets/social/5.png'
import social6 from '../../assets/social/6.png'
import social7 from '../../assets/social/7.png'
import social8 from '../../assets/social/8.png'
import social9 from '../../assets/social/9.png'
import social10 from '../../assets/social/10.png'
import social11 from '../../assets/social/11.png'
import social12 from '../../assets/social/12.png'

interface IKabanaHomeProps {
  tierId: string;
  referralId: string;
  isMobile:any;
  currentWallet:any;
  nftAmount:any;
  userId:any;
  
}
const KabanaHome: React.FC<IKabanaHomeProps> = props => {
  const { tierId, referralId ,isMobile,currentWallet, userId, nftAmount} = props;
  const [referral, setReferral] = useState<any>();
  const [tier, setTier] = useState<any>();
  const [wallet, setWallet] = useState<any>('');
  const [nftCount, setNftAmount] = useState<any>();
  const [userID, setUserId] = useState<any>();
  const [userTier, setCurrentTierObj] = useState<any>(); 
  const currentTierObj:any = {
    1:{
      key:"1",
      
        "PREFERENTIAL_PRICING":{
          title:"Preferential pricing across all KCLUB GROUP:",
          list:[
          "50% off food in all K Club restaurants",
          "20% off K Club accommodations",
          "10% off beverage at K Club Group"
        ]},
        "CONCIERGE":{
          title:"Access to a VIP Concierge service through our proprietar app:",
          list:[
          "A fleet of yachts and helicopter shuttle service to travel between our Bali resorts: Ubud, Canggu, Nusa Dua, Ceningan.",
          "A dedicated 24/7 concierge service for our members"
        ]},
        "EVENTS":{
          title:"Access to VIP Private Events:",
          list:[
          "The entrance will be restricted to members only.",
          "12 events per year with top international DJ's."
        ]},
        "NFT":{
          title:"NFT TOKEN & PROPORTIONAL SHARES OF KABANA UBUD:",
          list:[
          "The NFT artworks are designed by the top Indonesian artist, Monez.",
          "You can trade your NFT. After the minting, the value of your NFT will be at least 3x. "
        ]},
        "COMMUNITY":{
          title:"Become a member of a new kind and unique community in Bali:",
          list:[
            "The KABANA TRIBE Membership allows you to grow your asset in a few months.",
            "Today, you have the opportunity to become one of the 50 silent investors by referring 10 like-minded investors who’d like to join the Kabana Tribe.",
            "Can you think of 10 friends who’d love to be part of an exclusive membership club like Kabana Tribe?",
            "If you’re worried that you can’t think of 10 friends, each new member you’ll manage to refer us will get you a 10% commission for every sale!",
            "If you’re reading this, it is because we believe you do!"
          ]
        },
        "TODO":{
          title:"Here is what you need to do:",
          subTitle:"If you manage to be first to get 10 people to join the KABANA TRIBE, you will receive your one of 50 Silent Investor membership (worth $10,000 in equity and certified by a notary).",
          list:[
            "Share your personal referral link with your network.",
            "Be first of 50 to get 10 people to purchase Exclusive KClub Membership NFT!"
            
          ]
        },
        "INFO":{
          title:"The project is backed by Bali's most prominent real estate developer:",
          list:[
            "When you invest with K Club NFT, you buy in existing assets. The facilities are already existing and operating. Unlike most NFT projects that sell you a potential future, we offer you real-life benefits that you can use immediately.  By doing this, we can immediately grow the volume of assets and increase the value of existing investments. The more people use our facilities, the more revenue is generated and the more the value of the share increases.",
            
          ]
        },
        "MEMBER":{
          title:"This very limited KABANA TRIBE Membership sale at $1000 gets you:",
          list:[ 
                "A lifetime membership to all K Club locations in Indonesia",
                  "An unique opportunity to become a silent investor",
                  "A great potential to grow your asset value over the years",
                  "Access to Bali's most exclusive private clubs",
                  "The ownership of Bali's first NFT KClub token",
                  "An early-bird $1000 Membership initially valued at $3000",
          ]
        }
      
    },
    2:{
      key:"2",
      
        "PREFERENTIAL_PRICING":{
          title:"Preferential pricing across all KCLUB GROUP:",
          list:[
          "50% on food in K Club Group (Akar Ubud, Cemagi and Nusa Dua, Kabana Ubud, Kabana Ceningan).",
          "25% discounts on a selection of prestigious venues in Bali (beach clubs, hotels, restaurants)",
          "10% off beverages at K-Club Group."
        ]},
        "CONCIERGE":{
          title:"Access to a VIP Concierge service through our proprietar app:",
          list:[
          "A fleet of yachts and helicopter shuttle service to travel between our Bali resorts: Ubud, Canggu, Nusa Dua, Ceningan.",
          "A dedicated 24/7 concierge service for our members"
        ]},
        "EVENTS":{
          title:"Access to VIP Private Events:",
          list:[
          "The entrance will be restricted to members only.",
          "12 events per year with top international DJ's."
        ]},
        "NFT":{
          title:"NFT TOKEN & PROPORTIONAL SHARES OF KABANA UBUD:",
          list:[
          "The NFT artworks are designed by the top Indonesian artist, Monez.",
          "You can trade your NFT. After the minting, the value of your NFT will be at least 3x. ",
          "You will get shared parts on Bali’s fastest growing luxury group."
        ]},
        "COMMUNITY":{
          title:"Become a member of a new kind and unique community in Bali:",
          list:[
            "The KABANA TRIBE Membership allows you to grow your asset in a few months.",
            "Today, you have the opportunity to become one of the 500 silent investors by referring 5 like-minded investors who would like to join the Kabana Tribe.",
            "Can you think of 5 friends who would love to be part of an exclusive membership club like Kabana Tribe?",
            "If you’re worried that you can’t think of 5 friends, each new member you’ll manage to refer us, will get you a 10% commission for every sale!",
            "If you’re reading this, it is because we believe you do!"
          ]
        },
        "TODO":{
          title:"Here is what you need to do:",
          subTitle:"If you manage to be first to get 5 people to join the KABANA TRIBE, you will receive your one of 500 Silent Investor membership (worth $5,000 in equity and certified by a notary).",
          list:[
            "Share your personal referral link with your network.",
            "Be first of 100 to get 5 people to purchase Exclusive KClub Membership NFT!"
            
          ]
        },
        "INFO":{
          title:"The project is backed by Bali's most prominent real estate developer:",
          list:[
            "When you invest with K Club NFT, you buy in existing assets. The facilities are already existing and operating. Unlike most NFT projects that sell you a potential future, we offer you real-life benefits that you can use immediately.  By doing this, we can immediately grow the volume of assets and increase the value of existing investments. The more people use our facilities, the more revenue is generated and the more the value of the share increases.",
            
          ]
        },
        "MEMBER":{
          title:"This very limited KABANA TRIBE Membership sale at $1000 gets you:",
          list:[ 
                "A lifetime membership to all K Club locations in Indonesia",
                  "An unique opportunity to become a silent investor",
                  "A great potential to grow your asset value over the years",
                  "Access to Bali's most exclusive private clubs",
                  "The ownership of Bali's first NFT KClub token",
                  "An early-bird $1000 Membership initially valued at $3000",
          ]
        },
      
    },
    3:{
      key:"3",
      
        "PREFERENTIAL_PRICING":{
          title:"Preferential pricing across all KCLUB GROUP:",
          list:[
            "50% on food in K Club Group (Akar Ubud, Cemagi and Nusa Dua, Kabana Ubud, Kabana Ceningan).",
            "25% discounts on a selection of prestigious venues in Bali (beach clubs, hotels, restaurants)",
            "10% off beverages at K-Club Group."
        ]},
        "CONCIERGE":{
          title:"Access to a VIP Concierge service through our proprietar app:",
          list:[
          "A fleet of yachts and helicopter shuttle service to travel between our Bali resorts: Ubud, Canggu, Nusa Dua, Ceningan.",
          "A dedicated 24/7 concierge service for our members"
        ]},
        "EVENTS":{
          title:"Access to VIP Private Events:",
          list:[
          "The entrance will be restricted to members only.",
          "12 events per year with top international DJ's."
        ]},
        "NFT":{
          title:"NFT's:",
          list:[
          "The NFT artworks are designed by the top Indonesian artist, Monez.",
          "You can trade your NFT. After the minting, the value of your NFT will be at least 3x. "
        ]},
        "MEMBER":{
          title:"This very limited KABANA TRIBE Membership sale at $1000 gets you:",
          list:[ 
                "A lifetime membership to all K Club locations in Indonesia", 
                "A great potential to grow your asset value over the years",
                "Access to Bali's most exclusive private clubs",
                "The ownership of Bali's first NFT KClub token",
                "An early-bird $1000 Membership initially valued at $3000",
          ]
        },
        "TODO":{
          title:"GET 1500$ VOUCHER:",
          list:[
          "500$ Food voucher to spend at world class restaurant Akar Ubud, Cemagi and Nusa Dua, Kabana Ubud, Ceningan and Gili Gede",
          "500$ Voucher for accommodation (K Club Villas)",
          "500$ for VIP services (yacht , helicopter)"
        ]
        },
        "COMMUNITY":{
          title:"Become a member of a new kind and unique community in Bali:",
          list:[
            "Today acquiring a Kabana Tribe NFT Membership will allow you to be part of an exclusive private club with access to unique luxury lifestyle properties, experiences and premium VIP events with International DJ's across Indonesia… while your membership value keeps growing! ",
            
          ]
        }
         
      
    }}
     
    // if(referralId && referralId.length > 0 && referral === undefined ){
    //   setReferral(referralId[0])
    // }
     
    // if(tierId  && tierId.length > 0 && tier === undefined){ 
      
    //     setTier( parseInt(tierId[0]) );
        
    //     setCurrentTierObj(currentTierObj[parseInt(tierId[0])])
 
    // }
    const renderSocialLinks = (t:any) => { 
      var randomNum =  Math.random() * (1000 - 5) + 5;
    var currentRandNumb = parseInt(randomNum+'');
     
      return socialLinksPurchase.map(( { link, icon, label, className, name }, index) => (
       
        <a key={index} style={{marginLeft:'2px', marginTop:'10px'}} rel="noopener noreferrer" target="_blank" href={link+'?referral='+userID+'_'+(t+1)+''+currentRandNumb} title={name} className={'btn '  + className }>
          <span style={{ paddingRight: '10px' }}>{label}</span> 
          <i className={ icon} />
        </a>
      ));
    };
    useEffect(() => {
      
      if(currentWallet &&  currentWallet !== '' ){
        setWallet(currentWallet) 
        
         
        if(userId &&  userId !== undefined ){
          if(userId.affiliate_id){
            setReferral(userId.affiliate_id)
          }
           
          setUserId(userId.id);
          if( userId.nftmint_count !== undefined ){
            setNftAmount(userId.nftmint_count)
          }
          if(userId.tier !== null){
            setTier( parseInt(userId.tier) );
            if(currentTierObj[parseInt(userId.tier)] !== undefined){ 
              setCurrentTierObj(currentTierObj[parseInt(userId.tier)])
            }
          }
           
           
        }
         
     }else{
         setWallet('')
     }
   
    }, [tierId, referralId,currentWallet,nftAmount,userId]);
  
  
  return (
    <div
    className="App-header"
    style={{
      left: '0px',
      position: 'relative',
      top: '0px',
      backgroundColor: 'inherit',
      color: 'inherit',
      width: 'calc(100%)',
      margin: '0px 0px',
      padding: '0px 0px 0px 0px',
      paddingLeft: isMobile  ? '0px':'0px',
    }}
  >
      <SEO title="WiseGroup" />

      {/** HEADING SECTION */}
      <HeaderSection nftCount={nftCount} wallet={wallet} userTier={userTier} />
      {/* <Section gap image={firstGapImageBackground} /> */}

      {/** MEMBERSHIP SECTION */}
      {/* <Section className={classNames('bg-secondary', 'text-center text-white')}> */}
        {/* — Title */}
        {/* <div className={classNames('bg-primary', 'lg:pb-8')}>
          <Container>
            <div
              className={classNames('bg-secondary rounded-lg', 'px-2 py-4 -translate-y-6 lg:p-14')}
            >
              <h1 className="mb-0">Our Values </h1>
            </div>
          </Container>
        </div> */}

        {/* — Introduction */}
        {/* <Section.Container className="mb-2">
        <h2 className="mb-2 pt-10">Our Values</h2>
          <div className="mb-8 grid grid-cols-1 gap-16 md:grid-cols-2 lg:grid-cols-4">
            {data.values.map(({ title, image, content }, index) => (
              <Card key={index}>
                <img alt={title} src={image} className="mx-auto" />
                <Card.Body>
                  <Card.Title>{title}</Card.Title>
                  <Card.Text>{content}</Card.Text>
                </Card.Body>
              </Card>
            ))}
          </div>
          
        </Section.Container> */}
        {/* <Section.Container className="lg:pb-20">
           <div className="d-flex w-100 justify-content-center h-auto "> These values combine, leading to desirability, real-world value-for-money and client satisfaction.</div> 
           <div className="d-flex w-100 justify-content-center h-auto "> Poolwise has thus met the needs of all three commercial developers and individual homeowners with different pool requirements. For customers seeking best-in-class swimming pools, and pro-fessional, straightforward service, contact Poolwise to meet your needs.</div> 
        </Section.Container> */}
        {/* — Carousel */}
        {/* <Container className="mb-10">
          <ImageSlider
            images={[
              'https://kabanatribe.com/wp-content/uploads/elementor/thumbs/Copie-de-Copy-of-DSC01007-pqljpysbizi15efjg3q1yieyuf4m7pe5tr4x7qu3z4.jpg',
              'https://kabanatribe.com/wp-content/uploads/elementor/thumbs/Copie-de-Copy-of-7U7A2218-1-1-pqljq4fcnzpr327cj65tdgzqeqcthw0juj1u3elqxs.jpg',
              'https://kabanatribe.com/wp-content/uploads/elementor/thumbs/Copie-de-Copy-of-7U7A2102-1-pqljq6b11nsbqa4m86z2iginli3jxa80isct1yiylc.jpg',
              'https://kabanatribe.com/wp-content/uploads/elementor/thumbs/Copie-de-Copy-of-DSC00980-pqlkmphxl8blkeve9987t8yzrlqwc5dkbt7t0qaf8g.jpg',
              'https://kabanatribe.com/wp-content/uploads/elementor/thumbs/DSC00068-1-ppri8472znzui20qs8dkmtzga948pvpil37z7cfyqo.jpg',
              'https://kabanatribe.com/wp-content/uploads/elementor/thumbs/Copie-de-Copy-of-7U7A1786-pqlkmhz82k1azj6bh5z79avb0irymkjpmrzx6ilkm8.jpg',
            ]}
            settings={{
              dots: false,
              arrows: false,
            }}
          />
        </Container> */}

         

        {/* — Services */}
        {/* <Section.Container className="mb-10">
          <h2 className="mb-1">TOTAL POOL AND WATER MANAGEMENT SERVICES:</h2>
          <p className="mb-12">Poolwise provides, as standard, several services, including:</p>
          <div className="mb-8 grid grid-cols-1 gap-16 md:grid-cols-2 lg:grid-cols-4">
            {data.services.map(({ title, image, content }, index) => (
              <Card key={index}>
                <img alt={title} src={image} className="mx-auto" />
                <Card.Body>
                  <Card.Title>{title}</Card.Title>
                  <Card.Text>{content}</Card.Text>
                </Card.Body>
              </Card>
            ))}
          </div>
        </Section.Container> */}

        {/* <Section.Container className="mb-10">
          <h2 className="mb-1">Maintenance:</h2> 
          <div className="mb-8 grid grid-cols-1 gap-16 md:grid-cols-2 lg:grid-cols-4">
            {data.maintenance.map(({ title, image, content }, index) => (
              <Card key={index}>
                <img alt={title} src={image} className="mx-auto" />
                <Card.Body>
                  <Card.Title>{title}</Card.Title>
                  <Card.Text>{content}</Card.Text>
                </Card.Body>
              </Card>
            ))}
          </div>
        </Section.Container> */}
        {/* <Section.Container className="mb-10">
          <h2 className="mb-1">Consultancy:</h2> 
          <div className="mb-8 grid grid-cols-1 gap-16 md:grid-cols-2 lg:grid-cols-4">
            {data.consultancy.map(({ title, image, content }, index) => (
              <Card key={index}>
                <img alt={title} src={image} className="mx-auto" />
                <Card.Body>
                  <Card.Title>{title}</Card.Title>
                  <Card.Text>{content}</Card.Text>
                </Card.Body>
              </Card>
            ))}
          </div>
        </Section.Container> */}

        {/* — Venues: Current */}
        {/* <Section.Container large className="mb-10">
          <h2 className="mb-12">Our Clients:</h2>
          <div className="grid grid-cols-1 gap-16 md:grid-cols-2 lg:grid-cols-3">
            {data.venues.current.map(({ title, image, content }, index) => (
              <Card key={index}>
                <img alt={title} src={image} className="mx-auto" />
                <Card.Body>
                  <Card.Title>{title}</Card.Title>
                  <Card.Text>{content}</Card.Text>
                </Card.Body>
              </Card>
            ))}
          </div>
        </Section.Container> */}

        {/* — Venues: Next */}
        {/* <Section.Container className="mb-10">
          <h2 className="mb-12">Coming Soon:</h2>
          <div className="grid grid-cols-1 gap-16 md:grid-cols-2 lg:grid-cols-3">
            {data.venues.next.map(({ title, image, content }, index) => (
              <Card key={index}>
                <img alt={title} src={image} className="mx-auto" />
                <Card.Body>
                  <Card.Title>{title}</Card.Title>
                  <Card.Text>{content}</Card.Text>
                </Card.Body>
              </Card>
            ))}
          </div>
        </Section.Container> */}

         
      {/* </Section> */}

      {/* <Section gap image={secondGapImageBackground} className="hidden lg:block" /> */}

     

      {/** TESTIMONIALS SECTION */}
      {/* <Section className="bg-secondary text-white text-center py-10">
        <div style={{width:'90%' }} className="container max-w-5xl relative">
          <h2 className='mb-8'>What our clients say:</h2>
          <Testimonials className="mb-12" />
        </div>
      </Section> */}

      {/** MEMBERS SECTION */}
      {/* <Section className="bg-secondary text-white text-center py-10">
      <div style={{width:'100%' }} className="container max-w-5xl relative">
          
        <h2 className="mb-8">Social Post:</h2>
          <div className="mb-16 mx-auto max-w-3xl">
            <ImageSlider
              className="w-full h-96 md:w-60 md:h-64"
              images={[
                social1,
                social2,
                social3,
                social4,
                social5,
                social6,
                social7,
                social8,
                social9,
                social10,
                social11,
                social12,
                // 'https://www.instagram.com/p/C3r5VuEvwYX/',
                // 'https://www.instagram.com/p/C3mShqniANW/',
                // 'https://www.instagram.com/p/C3Xe9IoKCiu/',
                // 'https://www.instagram.com/p/C29wytPKgIt/',
                // 'https://www.instagram.com/p/C2eU5x-BPIw/',
                // 'https://www.instagram.com/p/Cz52nVMLD6W/',
                // 'https://www.instagram.com/p/CzBL8ahPAPb/'
              ]}
              settings={{
                dots: true,
                arrows: true,
                draggable: true,
                autoplaySpeed: 5000,
              }}
            />
          </div>

          {/* <img className="w-44 md:w-96 mb-4 mx-auto" src={logoHorizontal} alt="PoolWise Tribe" /> */}
        {/* </div> */}
      {/* </Section> } */}
      { <Footer /> }
    </div>
  )
 }
export default KabanaHome;
