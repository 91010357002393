import React from 'react';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <footer style={{display:'none', zIndex:'999',float:'left', width:'100%', left:'px',   color:'inherit',  backgroundColor:'inherit', height:'auto', bottom:'0px', textAlign:'left',  position:'fixed', padding:'10px' , paddingLeft:'60px'}}>
      <span >
        {/* <Link
          title="T&C"
          to={"/terms-and-conditions"}
          style={{ backgroundColor: 'inherit', color: 'inherit' }}
        >
          <small style={{color:'#427259', fontWeight:'bold'}}> T&C</small>
          </Link>
          |
          <Link
          title="Privacy Policy"
          to={"/privacy-policy"}
          style={{ backgroundColor: 'inherit', color: 'inherit' }}
          >
          <small style={{color:'#427259', fontWeight:'bold'}}> Privacy Policy</small>
           </Link>
           | */}
          <small > © COPYRIGHT POOLWISE 2024</small>
          
     </span>

    </footer>
  );
}

export default Footer;