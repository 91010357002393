import React, { ReactElement } from 'react'
import classNames from 'classnames'

import GradientOverlay from './GradientOverlay'
import Gap from './Gap'

/**
 * Section Props Definition
 */
type SectionProps = {
  /**
   * The section content.
   */
  children?: ReactElement[] | ReactElement | string

  /**
   * Add classNames to the section.
   */
  className?: string

  /**
   * Is the current section a gap?
   * A gap is just an empty space.
   */
  gap?: boolean

  /**
   * Set a gradient as overlay?
   */
  gradient?: boolean

  /**
   * The section tag.
   * The tag is either `section` or `header`.
   */
  header?: boolean

  /**
   * Set a background image to the section.
   */
  image?: string
}

/**
 * Section Component
 */
const Section: React.FC<SectionProps> = (props): ReactElement => {
  const { className, children, gap, gradient, image, header } = props

  /**
   * Compute the section className.
   */
  const sectionClassNames: string = classNames(
    'relative',
    'bg-no-repeat',
    'bg-cover',
    'lg:bg-fixed',
    className
  )

  /**
   * Style to add an image as backgroun.
   */
  const imageStyle: { backgroundImage?: string; backgroundPosition?: string } =
    typeof image === 'undefined'
      ? {}
      : {
          backgroundImage: `url(${image})`,
          backgroundPosition: 'center left',
        }

  return (
    <>
      {header ? (
        <header className={sectionClassNames} style={{ ...imageStyle }}>
          <Gap enabled={!!gap} />
          <GradientOverlay enabled={!!gradient} />
          {children}
        </header>
      ) : (
        <section className={sectionClassNames} style={{ ...imageStyle }}>
          <Gap enabled={!!gap} />
          <GradientOverlay enabled={!!gradient} />
          {children}
        </section>
      )}
    </>
  )
}

export default Section
