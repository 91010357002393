import React from 'react'
import classNames from 'classnames'

interface IDividerProps {
  className?: string
}

const Divider: React.FC<IDividerProps> = ({ className }) => (
  <div className={classNames('w-2/4 py-4', className)}>
    <span className="block w-full h-px bg-white" />
  </div>
)

export default Divider
