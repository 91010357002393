import React from 'react';
import TCPageForm from '../../components/TCPageForm';

const TCPage: React.FC = props => {
    return(
        <div
            className="App-header"
            style={
                {
                    left: '0px',
                    position: 'relative',
                    top: '0px',
                    backgroundColor: 'inherit',
                    color: 'inherit',
                    width: 'calc(100%)',
                    margin: '0px 0px',
                    padding: '0px 0px 0px 0px',
                    paddingLeft:'0px',
                }
            }
        > <TCPageForm className="mb-20"></TCPageForm> </div>
    );
};
export default TCPage;