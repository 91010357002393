import React, { Component } from 'react';
import axios from 'axios' ;
export default class EmailValidation extends Component {
  
  constructor(props) {
    super(props);
    
    this.state = {
      userId:this.props.match.params.id,
      serverPath:this.props.serverPath,
       bearerTocken : this.props.bearerTocken,
    };
     
  }
   
    updateUserStatus = async(id) =>{
        
        //var currentUserInfo = this.state.userData;
       // currentUserInfo['email_status'] = "1";

 

    var config  = {
        method: 'put',
        url:this.state.serverPath+"api/verify/" + id,
        headers: { 
          'Authorization': this.state.bearerTocken
        }, 
        data:{'email_status':"1"}
        
      };
    await axios(config).then(
      (res)=>{
        this.props.history.push('/');
    })


  }
  async UNSAFE_componentWillMount () {
    this._isMounted = true;
    try {
       
        
       
        if(this._isMounted){
            this.updateUserStatus(this.state.userId)
           // console.log(this.state.userId, "@@@@@@@@@@@@@@@@@@@@@@@@2")
        } 
    } catch (error) {
      ////console.log(error);
    }
  }
   
  componentDidMount = async() => {
    this._isMounted = true;
   // console.log(this.state.userId, "@@@@@@@@@@@@@@@@@@@@@@@@2");
     
  }
 
     

  render() {
    return (

      <div className="App-header"  style={{left: '0px', position:'relative', top:'0px', backgroundColor:'inherit', color:'inherit', width:'calc(100%)', margin:'0px 0px',  padding:'0px 0px 0px 0px'}}>
        <div className="row  " style={{marginTop:'0px', padding:'0px',paddingTop:' 90px', paddingBottom: '30px',backgroundColor:'inherit', color:'inherit'}}>
         {  this._isMounted  ?  
         <div className={  "col-md-12 col-sm-12 col-xs-12" } style={{fontWeight:'200', height:'auto', padding:'0px', backgroundColor:'inherit', color:'inherit'}} >
              
            <div className={  "col-md-12 col-sm-12 col-xs-12" }   >
               <div className="alert alert-success text-center"  style={{width:'100%'}}> 
                        Thanks for validating the email!.    
                 </div>
            </div>
                  
                 
              
             
       
          </div>
          :<></>}
       
        </div>
      </div>
    )
  }
  componentWillUnmount = () => {
     
    this._isMounted = false; 
     
    
 }
}
