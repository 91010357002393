import React from 'react'
import classNames from 'classnames'

import Section from '../../../components/kabana/Section'
import Divider from '../../../components/kabana/Divider'
import Button from '../../../components/kabana/Button'
import Review from '../../../components/kabana/Review'
// import Video from '../../components/Video'

import backgroundCoverImage from '../../../assets/images/covers/cover1.png'
// @ts-ignore
import introductionVideo from '../../../assets/videos/intro.mp4'

interface IHeaderSectionProps {
  wallet:any;
  userTier:any
  nftCount:any
  
}
const HeaderSection: React.FC<IHeaderSectionProps> = (props) => {
  const { userTier, wallet, nftCount} = props;
  return(
  <Section
    header
    gradient
     
    className={classNames('flex flex-col items-center', 'py-4', 'text-white')}
  >
    <Section.Container className="lg:py-8">
      <div
        className={classNames(
          'intro-content',
          'mb-4 lg:mb-10',
          'text-center'
        )}
      >
        {/***
         * Group #1: Title, Subtitle and Divider.
         */}
        <div className="intro-heading lg:px-4" style={{marginTop:'40px', padding:'0px 15px'}}>
          {/* <img
            className="hero-logo w-44 md:w-64 mb-4 mx-auto lg:mx-0"
            src={logoHorizontal}
            alt="Kabana Tribe"
          /> */}
          <h1 className="hero-title font-bold">
            Welcome to the Wise Group of companies
          </h1>
          <div className={classNames('intro-content',
          'text-center lg:text-left')}></div>
          <p className="hero-subtitle text-lg font-normal">
            We bring professional western standards to the most critical equipment in your biggest asset!
          </p>
          <Divider className="mb-4 mx-auto lg:mx-0" />
        </div>
        <div
          className={classNames(
            'intro-content',
            'lg:grid lg:grid-cols-2 lg:gap-x-8 lg:auto-rows-min',
            'mb-4 lg:mb-10',
            'text-center lg:text-left'
          )}
        >
          <a href="https://poolwiseindo.com/home" target="_self" className="hero-action btn btn-primary" style={{display:'inline-block', borderColor:'#fff', cursor:'pointer', margin:'10px 10px', backgroundColor:'#363049'}}>
            <span className="pull-right fa fa-external-link " style={{padding:'10px',display: 'grid'}}></span>
            <span  style={{display:'inline-block'}}>
            
              <img src="icons/PoolWiseLogo.svg" style={{'width':'300px', display:'inline-block', padding:'2px 10px', 'height':'auto'}} alt="Pool Wise"></img>
            </span>
               
          </a>
          <a href="https://tradewiseindo.com/home" target="_self"  className="hero-action btn btn-primary " style={{display:'inline-block', borderColor:'#fff', cursor:'pointer', margin:'10px 10px', backgroundColor:'#363049'}}>
            <span className="pull-right fa fa-external-link " style={{padding:'10px',display: 'grid'}}></span>
            <span   style={{display:'inline-block'}}>
           
            <img src="icons/TradeWiseLogo.svg" style={{'width':'300px', display:'inline-block', padding:'2px 10px', 'height':'auto'}}  alt="Trade Wise"></img>
            </span>
          </a>
        </div>
        {/**
         * Group #2: Video.
         */}
        {/* <div
          className={classNames(
            'intro-video-container',
            'flex items-center mb-4',
            'lg:col-start-2 lg:col-end-3',
            'lg:row-start-1 lg:row-end-3',
            'lg:self-start',
            'lg:pl-6 lg:translate-y-5'
          )}
        >
          <video className="" src={introductionVideo} controls controlsList="nodownload" /> */}
          {/* <Video
          options={{
            autoplay: true,
            controls: true,
            responsive: true,
            fluid: true,
            sources: [
              {
                src: introductionVideo,
                type: 'video/mp4',
              },
            ],
          }}
        /> 
        </div>*/}

        {/**
         * Group #3: Description and CTA.
         */}
        {/* <div
          className={classNames(
            'intro-body lg:px-4'
            // 'lg:col-start-2 lg:col-end-3',
            // 'lg:row-start-1 lg:row-end-2'
          )}
        >
          <p className="hero-description mb-8 lg:text-lg">
            Poolwise brings  high quality Australian standard of pool regulations and testing to the masses in Bali and Indonesia. 
            Setting the standard in modern swimming pools for all beach  clubs, hotels, and villas with industry-leading technology,  
            standards, customer service and technical leadership and  design.

          </p>

          {/* <div className="hero-action">
           {userTier !== undefined && nftCount === 0 && wallet !== '' ?  <Button to="/presale/bsc">Total pool and water management</Button> :<></>}
          </div> 
        </div> */}
        
        {/* <div
          className={classNames(
            'intro-body lg:px-4'
            // 'lg:col-start-2 lg:col-end-3',
            // 'lg:row-start-1 lg:row-end-2'
          )}
        >
          {/* <div className="hero-action">
           {userTier !== undefined && nftCount === 0 && wallet !== '' ?  <Button to="/presale/bsc">Total pool and water management</Button> :<></>}
          </div>
        </div>*/}
      </div> 
      {/* <Divider className="mb-1 mx-auto lg:mx-0" />
      <div className={classNames('intro-content',
          'mb-10',
          'text-center lg:text-left')}>
        {<Review>
          <Review.Item className="hidden md:block"></Review.Item>
          <Review.Item className="hero-description mb-8 lg:text-lg">
            Our Mission is to focus on being able to deliver constant progress, feedback,
            and to work  continuously on improving them and the delivery of our performances to  
            better customer satisfaction.
          </Review.Item>
          <Review.Item className="hero-description mb-8 lg:text-lg">
            Our Vision is to be committed and  responsible in how we implement and conduct our services. 
            We aim to meet our client’s expectations through excellence in business practices and commitment to our activities.
          </Review.Item>
        </Review> } 
      </div>*/}
    </Section.Container>
  </Section>
  )
}
 
export default HeaderSection
