import React from 'react'
import classNames from 'classnames'

export interface IContainerProps {
  /**
   * The container children.
   */
  children: React.ReactElement | React.ReactElement[] | string

  /**
   * The container classnames.
   */
  className?: string

  /**
   * Should container take 100% of the parent width?
   */
  fluid?: boolean

  /**
   * Should container be `normal` or `large`?
   */
  large?: boolean
}

const Container: React.FC<IContainerProps> = ({ className, children, fluid, large }) => {
  return (
    <div
      className={classNames(
        'container',
        {
          'max-w-5xl': !large,
          'max-w-full': fluid,
          'w-full': fluid,
        },
        className
      )}
    >
      {children}
    </div>
  )
}
export default Container
